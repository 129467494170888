import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import ExpenseClinicianFacingComments from './ExpenseClinicianFacingComments';

const useStyles = makeStyles((theme) => ({
	formHeader: {
		borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
		paddingBottom: 8,
		marginBottom: 16,
	},
}));

const ReasonForSplittingForm = (props) => {
	const { index, expense, originalExpenseEntries, isReimbursement } = props;
	const { control, errors,setValue,watch,clearErrors } = useFormContext();
	const watchExpenseEntries = watch(`expenseEntries`);
	const [required, setRequired] = useState({ required: false });
	const isReadOnly = (function() {//If my tab original db expense is posted disable
		if (expense.id && expense.id > 0 && originalExpenseEntries && originalExpenseEntries.length > 0){
			let originalForPage = originalExpenseEntries.find(entry => entry.id === expense.id);
			if (originalForPage && originalForPage.status === "Posted" || originalForPage.status === "PendingSubmission"){//If my tab original db expense is posted disable
				return true;
			}
		}
		return false;
	})();  
	
	const watchSplitTransactionComment = watch(`expenseEntries[${index}].splitTransactionComment`);
	const classes = useStyles();
	
	useEffect(() => {
		for (let indexLoop = 0; indexLoop < watchExpenseEntries.length; ++indexLoop) {
			if (indexLoop != index){//If its not my comment
				let originalForPage = null;
				if (originalExpenseEntries && originalExpenseEntries.length > 0 && watchExpenseEntries[indexLoop].id && watchExpenseEntries[indexLoop].id > 0 ){
					originalForPage = originalExpenseEntries.find(entry => entry.id === watchExpenseEntries[indexLoop].id)
				}
				if (!originalExpenseEntries || !originalForPage || (originalForPage && originalForPage.status != "Posted")){//If the other tab original db expense is not posted update
					if (watchExpenseEntries[indexLoop].splitTransactionComment != watchSplitTransactionComment){
						setValue(`expenseEntries[${indexLoop}].splitTransactionComment`, watchSplitTransactionComment);
					}
				}
			}
		}
	}, [watchSplitTransactionComment, setValue])

	useEffect(() => {
		if (watchExpenseEntries.length > 1){
			setRequired({ required: true });
		}else{
			setRequired({ required: false });
			clearErrors(`expenseEntries[0].splitTransactionComment`);
		}
	}, [watchExpenseEntries.length])

	return (
		<div>
			<div>
				<Typography variant='h5' component='h5' className={classes.formHeader}>
					Transaction Reason
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12}>
					<Controller
						control={control}
						name={`expenseEntries[${index}].splitTransactionComment`}
						defaultValue={expense.splitTransactionComment || ''}
						rules={{
							required: required.required ? 'Split transaction reason is required' : false,
						}}
						render={({ onChange, value }) => (
								<ExpenseTextField
									multiline
									inputProps={{ maxLength: 100 }}
									minRows={3}
									id='split-transaction-details'
									onChange={onChange}
									value={value}
									label='Reason for splitting transaction'
									placeholder='Enter your split transaction details here'
									{...required}
									disabled={isReadOnly}
									error={Boolean(errors.expenseEntries?.[index]?.splitTransactionComment)}
									helperText={
										errors.expenseEntries?.[index]?.splitTransactionComment?.message
									}
								/>
							)}
						/>
					</Grid>
					{isReimbursement && <ExpenseClinicianFacingComments index={index} expense={expense} isReadOnly={isReadOnly} />}
				</Grid>
			</div>
		</div>
	);
};

export default ReasonForSplittingForm;

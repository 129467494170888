import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { BillTypeEnum } from '../Expense/BillType.enum';
import ExpenseDatePicker from './ExpenseDatePicker';
import moment from 'moment';
import { ExpenseStatusValuesEnum } from './ExpenseStatus.enum';

const ExpenseDateRange = (props) => {
    const { index, expense, mode, isPosted, isPendingSubmission } = props;
    const { control, errors, watch } = useFormContext();
    const billType = watch(`expenseEntries[${index}].billType`);
    const isClinicianDeduction = billType === 'ClinicianDeduction';
    const [required, setRequired] = useState({ required: true});
    const nonBillable =
        billType &&
        (billType === BillTypeEnum.NonBillTeam || billType === BillTypeEnum.NonBillProducer);
    const startDate = watch(`expenseEntries[${index}].startDate`);
    const endDate = watch(`expenseEntries[${index}].endDate`);
    const minStartDate = nonBillable
        ? moment(new Date()).startOf('day').subtract(1, 'year')
        : moment(new Date()).startOf('day').subtract(6, 'months');
    const twoMonthsOut = moment(new Date()).startOf('day').add(60, 'days');
    const maxStartDate = endDate ? moment(endDate).isSameOrBefore(twoMonthsOut) ? endDate : twoMonthsOut : twoMonthsOut;
    const minEndDate = startDate
        ? startDate
        : moment(new Date()).startOf('day').subtract(6, 'months');
    const maxEndDate = moment(new Date()).startOf('day').add(6, 'months');

    const handleDateChange = (e) =>
        e && e.startOf('day') ? e.startOf('day').format('YYYY-MM-DDTHH:mm:ss') : e;


    useEffect(() => {
        setRequired({ required: (mode !== 'cc' && !isClinicianDeduction) || (mode === 'cc' && expense?.status === ExpenseStatusValuesEnum.Approved)});
    }, [expense?.status]);

    return (
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].startDate`}
                        defaultValue={expense.startDate || null}
                        rules={{
                            required:
                                required.required ? ((!billType && 'Bill type is required') || 'Start date required') : false,
                            validate: {
                                invalid: (value) => !value || moment(value).isValid() || 'Invalid date',
                                maxDate: (value) => {
                                    if (!value) return true;
                                    if (endDate && moment(value).isAfter(endDate)) {
                                        return 'Start date must be before end date';
                                    }
                                    if (moment(value).isAfter(maxStartDate)) {
                                        return 'Start date must be within 60 days';
                                    }
                                    return true;
                                },
                                minDate: (value) =>
                                    !value || moment(value).isSameOrAfter(minStartDate) || 'Min date reached',
                            },
                        }}
                        render={({ onChange, value }) => (
                            <ExpenseDatePicker
                                onChange={(e) => onChange(handleDateChange(e))}
                                value={value}
                                id='start-date'
                                label='Start date'
                                {...required}
                                minDate={minStartDate}
                                maxDate={maxStartDate}
                                disabled={!billType || isPosted || isPendingSubmission}
                                error={Boolean(errors.expenseEntries?.[index]?.startDate)}
                                helperText={errors.expenseEntries?.[index]?.startDate?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].endDate`}
                        defaultValue={expense.endDate || null}
                        rules={{
                            required: required.required ? ((!billType && 'Bill type is required') || 'End date required') : false,
                            validate: {
                                invalid: (value) => !value || moment(value).isValid() || 'Invalid date',
                                maxDate: (value) =>
                                    !value || moment(value).isSameOrBefore(maxEndDate) || 'Max date reached',
                                minDate: (value) =>
                                    !value || moment(value).isSameOrAfter(minEndDate) ||
                                    'End date must be after start date',
                            },
                        }}
                        render={({ onChange, value }) => (
                            <ExpenseDatePicker
                                onChange={(e) => onChange(handleDateChange(e))}
                                value={value}
                                id='end-date'
                                label='End date'
                                {...required}
                                minDate={minEndDate}
                                maxDate={maxEndDate}
                                disabled={!billType || isPosted || isPendingSubmission}
                                error={Boolean(errors.expenseEntries?.[index]?.endDate)}
                                helperText={errors.expenseEntries?.[index]?.endDate?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ExpenseDateRange;

import React, { useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import { AdminAuthContext } from '../../../Admin/Contexts/AdminAuthContext.js';

const ExpenseClinicianFacingComments = (props) => {
    const { index, expense, isReadOnly } = props;
    const { control, watch, errors } = useFormContext();
    const expenseStatus = watch(`expenseEntries[${index}].status`);
    const isRequired = expenseStatus === 'NonReimbursable' || expenseStatus === 'NeedMoreInformation';
    const adminAuth = useContext(AdminAuthContext);

    return (
        <Grid item xs={12}>
            <Controller
                control={control}
                name={`expenseEntries[${index}].clinicianFacingComments`}
                defaultValue={expense.clinicianFacingComments}
                rules={{
                    required: isRequired ? 'Clinician facing comments are required' : false,
                }}
                render={({ onChange, value }) => (
                    <ExpenseTextField
                        required={isRequired}
                        multiline
                        inputProps={{ maxLength: 1000 }}
                        minRows={5}
                        id='clinicianFacingComments'
                        onChange={onChange}
                        value={value}
                        label='Clinician Facing Comments'
                        error={Boolean(errors.expenseEntries?.[index]?.clinicianFacingComments)}
                        disabled={isReadOnly || adminAuth.isLTExpenseAssociateUser}
                        helperText={errors.expenseEntries?.[index]?.clinicianFacingComments?.message ? errors.expenseEntries?.[index]?.clinicianFacingComments?.message : (value && `${value.length}/1000`)}
                        placeholder='Provide notes or instructions for clinicians'
                    />
                )}
            />
        </Grid>
    );
};

export default ExpenseClinicianFacingComments;
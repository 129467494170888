import React, { Fragment, Component } from 'react';
import { Grid, withStyles, CircularProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Flight, Launch } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import moment from 'moment';
import { COLORS } from '../../../../utils/Application_Constants';
import { getTravelItinerary } from '../../../../services/AssignmentsService';

const styles = (theme) => ({
	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
	title: {
		fontWeight: 'bold',
		fontSize: '16px',
		marginBottom: '4px',
		color: COLORS.RAVEN,
	},
	value: {
		fontSize: '14px',
		color: COLORS.RAVEN,
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		textDecoration: 'none',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
	},
	topRightIcon: {
		position: 'absolute',
		top: '8px',
		right: '16px',
	},
	flightCard: {
		marginTop: '5px',
		backgroundColor: 'white',
		padding: '16px',
		minWidth: '100%',
		borderRadius: '8px',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
		position: 'relative',
	},
	iconClass: {
		verticalAlign: 'middle',
		color: COLORS.TRUE_BLUE,
		transform: 'rotate(90deg)',
		fontSize: '36px',
	},
	buttonContainer: {
		display: 'flex',
		gap: '24px',
		marginTop: '8px',
		marginLeft: '16px'
	},
	buttonIcon: {
		marginRight: '8px',
		fontSize: '16px',
		color: COLORS.TRUE_BLUE,
		backgroundColor: COLORS.LIGHT_GRAY,
		borderRadius: '50%',
		padding: '4px',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
		verticalAlign: 'middle'
	},
	button: {
		background: 'none',
		border: 'none',
		color: COLORS.TRUE_BLUE,
		textDecoration: 'none',
		cursor: 'pointer',
		padding: '0',
		font: 'inherit'
	},
	disabledButton: {
		color: 'gray',
		cursor: 'not-allowed',
		textDecoration: 'none',
		pointerEvents: 'none'
	},
	section: {
		marginBottom: '12px',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
});

class FlightsV3 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	render() {
		const { classes, item, externalId, assignmentId } = this.props;
		const { isLoading } = this.state;

		return (
			<Fragment key={item.tripKey + '-' + item.flightNumber}>
				<Grid container direction="column" className={classes.flightCard}>
					<div className={classes.row}>
						<div className={classes.column}>
							<div className={classes.section}>
								<div className={classes.title}>FLIGHT</div>
								<div className={classes.value}>{`${item.originCityDesc} - ${item.destCityDesc}`}</div>
							</div>
							<div className={classes.section}>
								<div className={classes.title}>AIRLINE</div>
								<div className={classes.value}>{item.vendorName}</div>
							</div>
						</div>
						<div className={classes.topRightIcon}>
							<Flight className={classes.iconClass} />
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.column}>
							<div className={classes.section}>
								<div className={classes.title}>DEPARTURE</div>
								<div className={classes.value}>{`${moment(item.originatedDate).format('MM/DD/YYYY h:mm a')}`}</div>
							</div>
						</div>
						<div className={classes.column}>
							<div className={classes.section}>
								<div className={classes.title}>ARRIVAL</div>
								<div className={classes.value}>{`${moment(item.completedDate).format('MM/DD/YYYY h:mm a')}`}</div>
							</div>
						</div>
						<div className={classes.column}>
							<div className={classes.section}>
								<div className={classes.title}>CONFIRMATION</div>
								<div className={classes.value}>
									<InsertDriveFileOutlinedIcon fontSize='small' />
									{item.confirmationNumber}
								</div>
							</div>
						</div>
					</div>
					<div className={classes.buttonContainer}>
						<a
							href={item.eItinLink}
							className={classes.clickableLink}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Launch className={classes.buttonIcon} />
							Booking
						</a>
						<button
							onClick={async () => {
								this.setState({ isLoading: true });
								try {
									await getTravelItinerary(externalId, assignmentId, item.recordLocator);
								} catch (error) {
									console.error('Error downloading file:', error);
									alert('Failed to download the file. Please try again.');
								} finally {
									this.setState({ isLoading: false });
								}
							}}
							className={`${classes.button} ${this.state.isLoading ? classes.disabledButton : ''}`}
							disabled={isLoading}
							aria-label="Download Itinerary"
						>
							{this.state.isLoading ? (
								<CircularProgress size={20} className={classes.buttonIcon} />
							) : (
								<GetAppIcon className={classes.buttonIcon} />
							)}
							Download
						</button>
					</div>
				</Grid>
			</Fragment>
		);
	}
}

export default withTranslation()(withStyles(styles)(FlightsV3));


import { COLORS } from '../../utils/Application_Constants';
import { BillTypeEnum } from '../Components/Expense/BillType.enum';

const billTypes = [
    {
        type: 'Billable',
        value: BillTypeEnum.Billable,
    },
    {
        type: 'Non-Bill Team',
        value: BillTypeEnum.NonBillTeam,
    },
    {
        type: 'Non-Bill Producer',
        value: BillTypeEnum.NonBillProducer,
    },
    {
        type: 'Clinician Deduction',
        value: BillTypeEnum.ClinicianDeduction,
    },
];


const textFieldStyles = (theme) => ({
    root: {
        '& label + .MuiInput-formControl': {
            marginTop: '4px',
        },
        marginBottom: 0,
    },
    inputRoot: {
        border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
        fontSize: '14px',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: '#fff',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            borderColor: COLORS.LT_JOURNEY,
        },
        '&$disabled $input': {
            background: COLORS.LT_MIDNIGHTBG2,
            color: COLORS.LT_MIDNIGHT33,
        },
        marginBottom: '0',
    },
    input: {
        padding: '10px 12px !important',
        '&.MuiInputBase-inputAdornedStart': {
            paddingLeft: '0 !important',
        },
    },
    error: {
        borderColor: COLORS.LT_PEPPER,
    },
    inputLabelAsterisk: {
        color: COLORS.LT_PEPPER,
    },
    multiline: {
        padding: 0,
    },
    selectIcon: {
        color: COLORS.LT_MIDNIGHT25,
        right: '8px',
        fontSize: '1.25rem',
        top: 'calc(50% - 10px)',
    },
    disabled: {},
    focused: {},
});

const currencies = [
    { name: '$USD', value: 'USD', symbol: '$' },
    { name: '€EUR', value: 'EUR', symbol: '€' },
    { name: '$CAD', value: 'CAD', symbol: '$' },
    { name: 'د.إAED', value: 'AED', symbol: 'د.إ' },
    { name: '$MXN', value: 'MXN', symbol: '$' },
    { name: '$COP', value: 'COP', symbol: '$' },
    { name: '£GBP', value: 'GBP', symbol: '£' },
    { name: '₹INR', value: 'INR', symbol: '₹' },
    { name: '¥JPY', value: 'JPY', symbol: '¥' },
];

const states = [
    { name: 'Alabama', value: 'AL' },
    { name: 'Alaska', value: 'AK' },
    { name: 'Arizona', value: 'AZ' },
    { name: 'Arkansas', value: 'AR' },
    { name: 'California', value: 'CA' },
    { name: 'Colorado', value: 'CO' },
    { name: 'Connecticut', value: 'CT' },
    { name: 'District of Columbia', value: 'DC' },
    { name: 'Delaware', value: 'DE' },
    { name: 'Florida', value: 'FL' },
    { name: 'Georgia', value: 'GA' },
    { name: 'Hawaii', value: 'HI' },
    { name: 'Idaho', value: 'ID' },
    { name: 'Illinois', value: 'IL' },
    { name: 'Indiana', value: 'IN' },
    { name: 'Iowa', value: 'IA' },
    { name: 'Kansas', value: 'KS' },
    { name: 'Kentucky', value: 'KY' },
    { name: 'Louisiana', value: 'LA' },
    { name: 'Maine', value: 'ME' },
    { name: 'Maryland', value: 'MD' },
    { name: 'Massachusetts', value: 'MA' },
    { name: 'Michigan', value: 'MI' },
    { name: 'Minnesota', value: 'MN' },
    { name: 'Mississippi', value: 'MS' },
    { name: 'Missouri', value: 'MO' },
    { name: 'Montana', value: 'MT' },
    { name: 'Nebraska', value: 'NE' },
    { name: 'Nevada', value: 'NV' },
    { name: 'New Hampshire', value: 'NH' },
    { name: 'New Jersey', value: 'NJ' },
    { name: 'New Mexico', value: 'NM' },
    { name: 'New York', value: 'NY' },
    { name: 'North Carolina', value: 'NC' },
    { name: 'North Dakota', value: 'ND' },
    { name: 'Ohio', value: 'OH' },
    { name: 'Oklahoma', value: 'OK' },
    { name: 'Oregon', value: 'OR' },
    { name: 'Pennsylvania', value: 'PA' },
    { name: 'Rhode Island', value: 'RI' },
    { name: 'South Carolina', value: 'SC' },
    { name: 'South Dakota', value: 'SD' },
    { name: 'Tennessee', value: 'TN' },
    { name: 'Texas', value: 'TX' },
    { name: 'Utah', value: 'UT' },
    { name: 'Vermont', value: 'VT' },
    { name: 'Virginia', value: 'VA' },
    { name: 'Washington', value: 'WA' },
    { name: 'West Virginia', value: 'WV' },
    { name: 'Wisconsin', value: 'WI' },
    { name: 'Wyoming', value: 'WY' },
];

export { billTypes, textFieldStyles, currencies, states };

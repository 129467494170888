import { Box, Button, Grid, MenuItem, Typography, makeStyles } from '@material-ui/core';
import React, { useMemo, useState, useContext } from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import ExpenseDatePicker from './ExpenseDatePicker';
import StatusOption from './StatusOption';
import { HistoryOutlined } from '@material-ui/icons';
import { ExpenseClassEnum } from './ExpenseClass.enum'
import { ExpenseStatusEnum, ExpenseStatusValuesEnum, ExpenseAdminStatusValuesEnum } from './ExpenseStatus.enum';
import moment from 'moment';
import { AdminAuthContext } from '../../../Admin/Contexts/AdminAuthContext.js';


const useStyles = makeStyles((theme) => ({
    formHeader: {
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
        paddingBottom: 8,
        marginBottom: 16,
    },
}));

const InternalNotesForm = (props) => {
    const { index, expense, mode, statusesAllowed, originalExpenseEntries } = props;
    const classes = useStyles();
    const { control, errors } = useFormContext();
    const adminAuth = useContext(AdminAuthContext);
    const isExpenseAdminUser = !adminAuth?.isExpenseSystemAdministratorUser && adminAuth?.isExpenseAdminUser;
    let isExpenseAdminEditMode =  false;
    const statuses = useMemo(() => {
        if (mode != 'pending-cc') {
            let statusExp = null;
            // Find original unmodified expense to base new statuses allowed on
            if (!!originalExpenseEntries) statusExp = originalExpenseEntries.find(entry => entry.id === expense?.id);
            if (!statusExp) statusExp = expense; // default to live expense
            if (!statusesAllowed || statusesAllowed.length === 0) return [{ key: "New", value: "New" }];
            const allowedList = statusesAllowed[statusExp?.status ?? "New"] ?? ["New"];
            let filtered = Object.entries(ExpenseStatusEnum).filter(([key, value]) => allowedList.includes(value.value)).map(([key, value]) => value);
            //Set Expense Admin Edit Mode
            isExpenseAdminEditMode = isExpenseAdminUser && Object.values(ExpenseAdminStatusValuesEnum).includes(statusExp?.status);
            if (isExpenseAdminEditMode){//Override Allowed Statuses for Expense Admins in Edit Mode
                filtered = filtered.filter(status => Object.values(ExpenseAdminStatusValuesEnum).includes(status.value));
            }
            return filtered;
        }
    }, [statusesAllowed, expense]);
    //const { id } = useParams();
    const [curStatus, setCurStatus] = useState(expense.status);
    const isNew = expense.id ? false : true;
    const isPosted = expense.status === 'Posted';
    const isPendingSubmission = expense.status === 'PendingSubmission';
    const canEditStatus = !isPendingSubmission && !isPosted && (adminAuth?.isExpenseSystemAdministratorUser || isExpenseAdminEditMode);
    const createdDate = useMemo(
        () => moment(expense.created) && moment(expense.created).format('MM/DD/YYYY'),
        [expense]
    );
    const precodedCreatedDate = useMemo(
        () => moment(expense.precodedCreated) && moment(expense.precodedCreated).format('MM/DD/YYYY'),
        [expense]
    );

    const handleDateChange = (e) =>
        e && e.startOf('day') ? e.startOf('day').format('YYYY-MM-DDTHH:mm:ss') : e;

    const showHideDeliveryDate = (newStatus) => {
        // Need to trigger re-render of date control
        setCurStatus(newStatus);
    }
    
    return (
        <div>
            <Typography variant='h5' component='h5' className={classes.formHeader}>
                Internal Notes
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].notes`}
                        defaultValue={expense.notes || ''}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                multiline
                                inputProps={{ maxLength: 300 }}
                                minRows={3}
                                id='notes'
                                onChange={onChange}
                                value={value}
                                disabled={isPosted || isPendingSubmission}
                                label='Notes'
                                helperText={value && `${value.length}/300`}
                                placeholder='Internal notes here'
                            />
                        )}
                    />
                </Grid>
                {mode === 'pending-cc' || (expense?.id ?? 0) === 0 ? null :
                    <>
                        <Grid item xs={12} md={6}>
                            <Controller
                                control={control}
                                name={`expenseEntries[${index}].status`}
                                defaultValue={expense.status}
                                rules={{
                                    required: 'Status is required',
                                }}
                                render={({ onChange, value }) => (
                                    <ExpenseTextField
                                        id={'status'}
                                        select
                                        onChange={(e) => { onChange(e); showHideDeliveryDate(e.target.value); }}
                                        value={value}
                                        label={'Status'}
                                        required
                                        disabled={!canEditStatus}
                                        error={Boolean(errors.expenseEntries?.[index]?.status)}
                                        helperText={errors.expenseEntries?.[index]?.status?.message}
                                    >
                                        <MenuItem value='' disabled>
                                            Select a status
                                        </MenuItem>
                                        {statuses.map((status, index) => (
                                            <MenuItem
                                                key={status.value}
                                                value={status.value}
                                                id={`status-menu-option-${index}`}
                                            >
                                                <StatusOption status={status.value} label={status.UI} />
                                            </MenuItem>
                                        ))}
                                    </ExpenseTextField>
                                )}
                            />
                        </Grid>
                        {curStatus != ExpenseStatusValuesEnum.Approved ? null :
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={`expenseEntries[${index}].deliveryDate`}
                                    defaultValue={expense.deliveryDate || null}
                                    rules={{
                                        required: 'Delivery date is required',
                                        validate: {
                                            invalid: (value) =>
                                                !value || moment(value).isValid() || 'Invalid date',
                                            maxDate: (value) =>
                                                !value || moment(value).isSameOrBefore(moment().add(7, 'days')) || 'Max date reached',
                                            minDate: (value) =>
                                                !value || moment(value).isSameOrAfter(moment().startOf('day')) || 'Min date reached'
                                        },
                                    }}
                                    render={({ onChange, value }) => (
                                        <ExpenseDatePicker
                                            onChange={(e) => onChange(handleDateChange(e))}
                                            value={value}
                                            id='delivery-date'
                                            label='Delivery Date'
                                            minDate={moment()}
                                            maxDate={moment().add(7, 'days')}
                                            required
                                            disabled={isPosted || isPendingSubmission}
                                            error={Boolean(
                                                errors.expenseEntries?.[index]?.deliveryDate
                                            )}
                                            helperText={
                                                errors.expenseEntries?.[index]?.deliveryDate?.message
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        }
                        {curStatus != ExpenseStatusValuesEnum.OnHoldReimbursement ? null :
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name={`expenseEntries[${index}].onHoldComment`}
                                    defaultValue={expense.onHoldComment || ''}
                                    rules={{
                                        required: 'On Hold reason is required',
                                    }}
                                    render={({ onChange, value }) => (
                                        <ExpenseTextField
                                            multiline
                                            inputProps={{ maxLength: 200 }}
                                            minRows={3}
                                            id='on-hold-comment'
                                            onChange={onChange}
                                            value={value}
                                            label='On Hold Reason'
                                            placeholder='Enter the on hold reason here'
                                            required={true}
                                            error={Boolean(errors.expenseEntries?.[index]?.onHoldComment)}
                                            helperText={
                                                errors.expenseEntries?.[index]?.onHoldComment?.message
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        }
                    </>
                }
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} md={6}>
                            {!isNew && (
                                <Typography variant='body2'>
                                    {
                                        (expense.hasOwnProperty('wasMergedWithAPrecoded') && expense.wasMergedWithAPrecoded === true)
                                            ? `Pending CC Submitted by ${expense.precodedCreatedUserName} on ${precodedCreatedDate} `
                                            : (
                                                (expense.expenseClass === ExpenseClassEnum.IngestedCCTransaction)
                                                    ? `Imported by ${expense.createdUserName} on ${createdDate} `
                                                    : `Submitted by ${expense.createdUserName} on ${createdDate} `
                                            )
                                    }
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display={'flex'} justifyContent={'flex-end'}>
                                <Button startIcon={<HistoryOutlined />}>Expense History</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default InternalNotesForm;

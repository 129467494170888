import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import AmountCurrencyInputAdornment from './AmountCurrencyInputAdornment';
import { valueAsExpense } from '../../Utils/ValueAsUtils';
import { ExpenseStatusValuesEnum } from './ExpenseStatus.enum';

const ExpenseAmount = (props) => {
	const { disabled, index, expense, originalExpense, originalExpenseEntries, isReimbursement, mode, isPosted, isPendingSubmission, isAnyExpensePosted } = props;
	const { control, errors, setValue, watch } = useFormContext();
	const watchExpenseEntries = watch('expenseEntries');
	const [required, setRequired] = useState({ required: true });

	useEffect(() => {
		setRequired({ required: (mode !== 'cc' || expense?.status === ExpenseStatusValuesEnum.Approved) });
	}, [expense?.status]);

	const watchAmount = watch(`expenseEntries[${index}].amount`);
	const watchCurrency = watch(`expenseEntries[${index}].currency`);
	
	useEffect(() => {
		if (isReimbursement){
			for (let indexLoop = 0; indexLoop < watchExpenseEntries.length; ++indexLoop) {
				if (indexLoop != index){//If its not my amount
					let originalForPage = null;
					if (originalExpenseEntries && originalExpenseEntries.length > 0 && watchExpenseEntries[indexLoop].id && watchExpenseEntries[indexLoop].id > 0 ){
						originalForPage = originalExpenseEntries.find(entry => entry.id === watchExpenseEntries[indexLoop].id)
					}
					if (!originalExpenseEntries || !originalForPage || (originalForPage && originalForPage.status != "Posted")){//If the other tab original db expense is not posted update
						if (watchExpenseEntries[indexLoop].amount != watchAmount){
							setValue(`expenseEntries[${indexLoop}].amount`, watchAmount);
						}
					}
				}
			}
		}
	}, [watchAmount, setValue])

	useEffect(() => {
		if (isReimbursement){
			for (let indexLoop = 0; indexLoop < watchExpenseEntries.length; ++indexLoop) {
				if (indexLoop != index){//If its not my currency
					let originalForPage = null;
					if (originalExpenseEntries && originalExpenseEntries.length > 0 && watchExpenseEntries[indexLoop].id && watchExpenseEntries[indexLoop].id > 0 ){
						originalForPage = originalExpenseEntries.find(entry => entry.id === watchExpenseEntries[indexLoop].id)
					}
					if (!originalExpenseEntries || !originalForPage || (originalForPage && originalForPage.status != "Posted")){//If the other tab original db expense is not posted update
						if (watchExpenseEntries[indexLoop].currency != watchCurrency){
							setValue(`expenseEntries[${indexLoop}].currency`, watchCurrency);
						}
					}
				}
			}
		}
	}, [watchCurrency, setValue])

	return (
		<Grid item xs={12} md={6}>
			<Controller
				control={control}
				name={`expenseEntries[${index}].amount`}
				defaultValue={expense.amount}
				rules={{
					required: required.required ? 'Expense amount is required' : false,
				}}
				render={({ onChange, value }) => (
					<ExpenseTextField
						{...required}
						placeholder='0.00'
						id={`expense-amount`}
						onChange={(e) => onChange(valueAsExpense(e.target.value))}
						value={value}
						label='Submitted expense amount'
						endAdornment={
							<AmountCurrencyInputAdornment
								disabled={disabled || isPosted || isPendingSubmission || (!!expense.id && !isReimbursement)|| 
									(isReimbursement && expense.source ==='Clinician') ||
									(isReimbursement && index > 0) ||
									(isReimbursement && index == 0 && expense.source ==='LtAssociate' && isAnyExpensePosted) ||
									(isReimbursement && index == 0 && expense.source ==='LtAssociate' && originalExpense && !(originalExpense.status ==='NeedMoreInformation' || originalExpense.status ==='InProcess' || originalExpense.status ==='ReadyForReview' ))
								}
								index={index}
								currencyType={expense.currency}
							/>
						}
						error={Boolean(errors.expenseEntries?.[index]?.amount)}
						disabled={isPosted || 
							isPendingSubmission || 
							(isReimbursement && expense.source ==='Clinician') ||
							(isReimbursement && index > 0) ||
							(isReimbursement && index == 0 && expense.source ==='LtAssociate' && isAnyExpensePosted) ||
							(isReimbursement && index == 0 && expense.source ==='LtAssociate' && originalExpense && !(originalExpense.status ==='NeedMoreInformation' || originalExpense.status ==='InProcess' || originalExpense.status ==='ReadyForReview' ))
						}
						helperText={errors.expenseEntries?.[index]?.amount?.message}
					/>
				)}
			/>
		</Grid>
	);
};

export default ExpenseAmount;

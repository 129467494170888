import React, { useEffect, useState } from 'react';
import { Container, Grid, withStyles, CircularProgress, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import ExpenseButtons from '../Components/Expense/ExpenseButtons';
import ExpenseReceiptUploadForm from '../Components/Expense/ExpenseReceiptUploadForm';
import ExpenseEntryHeader from '../Components/Expense/ExpenseEntryHeader';
import { createExpense, 
    updateExpense, 
    getExpenseById, 
    getExpenseCategories, 
    getDivisions, 
    getStatusesAllowed } from '../Services/ExpenseService';
import { formatErrorMessage } from '../Utils/ErrorMessage';
import { uploadImages } from '../Utils/UploadImages';
import Alert from '../Components/Alert';
import ExpenseTabs from '../Components/Expense/Tabs/ExpenseTabs';
import ExpenseTab from '../Components/Expense/Tabs/ExpenseTab';
import ExpenseTabLabel from '../Components/Expense/Tabs/ExpenseTabLabel';
import AddIcon from '@material-ui/icons/Add';
import AddExpenseTab from '../Components/Expense/Tabs/AddExpenseTab';
import ExpenseTabPanel from '../Components/Expense/Tabs/ExpenseTabPanel';
import BookingDetailsForm from '../Components/Expense/BookingDetailsForm';
import ClinicianExpenseDetailsForm from '../Components/Expense/ClinicianExpenseDetailsForm';
import ExpenseSummary from '../Components/Expense/ExpenseSummary';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import InternalNotesForm from '../Components/Expense/InternalNotesForm';
import ReasonForSplittingForm from '../Components/Expense/ReasonForSplittingForm';
import { ExpenseStatusEnum } from '../Components/Expense/ExpenseStatus.enum';
import ExpenseNeedsReviewAlert from '../Components/Expense/ExpenseNeedsReviewAlert';
import _ from 'lodash';
import { COLORS } from '../../utils/Application_Constants';

const styles = (theme) => ({
    ContainerRoot: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        paddingTop: '1.5rem',
        paddingBottom: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
    },
    formGridContainer: {
        gap: 32,
    },
    summary: {
        position: "sticky",
        top: "5rem",
        paddingTop: '.5rem',
    },
    warningMessage: {
		color: COLORS.LT_SUNFLOWER
	},
});

const defaultExpense = {
    bookingId: null,
    clinicianId: '',
    clinicianFirstName: '',
    clinicianLastName: '',
    clientId: '',
    division: '',
    state: '',
    billType: '',
    category: '',
    type: '',
    currency: 'USD',
    amount: '',
    reimbursedAmount: '',
    comments: '',
    label: 'Expense',
    mileage: '',
    status: 'New',
    salesperson: '',
    recruiter: '',
    payDate: null,
    expenseClass: 1,
    splitTransactionComment: '',
    source: 'LtAssociate'
};

const ClinicianReimbursement = (props) => {
    const { classes } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [expenseCategories, setExpenseCategories] = useState(null);
    const [divisions, setDivisions] = useState([]);
    const [statusesAllowed, setStatusesAllowed] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const [alert, setAlert] = useState();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [removeTabs, setRemoveTabs] = useState(false);
    const backLink = id ? 'All Expenses' : 'Expense Types';
    const title = id ? 'Clinician Reimbursement' : 'Clinician Reimbursement Expense Entry';
    const expensesPath = id ? '/expenses/review' : '/expenses';
    const [originalExpenseEntries, setOriginalExpenseEntries] = useState([]);
    const defaultValues = {
        fileUploads: [{ newFiles: [], existingFiles: [] }],
        expenseEntries: [defaultExpense],
    };
    const methods = useForm({ shouldUnregister: false, defaultValues: defaultValues });
    const { handleSubmit, reset, control, getValues,watch,setValue, setError, clearErrors, formState: { errors }} = methods;
    const {
        fields: expenseEntries,
        append,
        remove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'expenseEntries', // unique name for your Field Array
        keyName: 'expenseEntryId',
    });
    const watchExpenseEntries = watch(`expenseEntries`);
    const isAnyApproved = Object.keys(watchExpenseEntries).some(function(k) {
		return watchExpenseEntries[k].status === "Approved";
	});
     const [showBalanceError, setShowBalanceError] = useState(false);
     const [showBalanceWarning, setShowBalanceWarning] = useState(false);
    useEffect(() => {
        Promise.all([getDivisions(), getStatusesAllowed('Reimbursement'), getExpenseCategories('NonSGA')])
            .then(async ([divs, statuses, cats]) => {
                setExpenseCategories(cats);
                setDivisions(divs);
                setStatusesAllowed(statuses);
            })
            .catch((error) => {
                console.error(error);
            });

        if (id) {
            fetchExpenseById();
        }
    }, []);

    //This was added to address a rendering issue
    //It turns off the TabPanel Display removes the item and then turns back on the display
    useEffect(() => {
        if (removeTabs){
            resetImages(activeTabIndex);
            remove(activeTabIndex);
            setActiveTabIndex(activeTabIndex - 1);
            setRemoveTabs(false);
        }
	}, [removeTabs])

    const fetchExpenseById = async () => {
        try {
            setIsLoading(true);
            const res = await getExpenseById(id);
            let listExpenses = []
            let expenseEntries = [];
            let aFileUploads = [];
            if (res.primaryExpenseId && res.primaryExpense){//child split
                let childIndex = 0
                listExpenses.push(res.primaryExpense);
                for (let i = 0; i < res.primaryExpense.splitExpenses.length; i++) {
                    let listExpensesIndex = listExpenses.push(res.primaryExpense.splitExpenses[i]);
                    if (res.primaryExpense.splitExpenses[i].id == id){
                        childIndex = listExpensesIndex - 1;
                    }
                }
                setActiveTabIndex(childIndex);
            }else{
                listExpenses.push(res);
                for (let i = 0; i < res.splitExpenses.length; i++) {
                    listExpenses.push(res.splitExpenses[i]);
                }
            }

            listExpenses[0].splitExpenses=[];

            for (let i = 0; i < listExpenses.length; i++) {
                let formattedValues = formatResponse(listExpenses[i]);
                //Reset back to Null as empty string on the as the default was causing issues
                formattedValues.payDate = (_.isString(formattedValues.payDate) && formattedValues.payDate.length === 0) ? null : formattedValues.payDate.substring(0, 10);
                expenseEntries.push({ ...formattedValues, label: listExpenses[i].label ? listExpenses[i].label : `Expense ${i}` });
                if (listExpenses[i] && listExpenses[i].expenseImages && listExpenses[i].expenseImages.length >= 0){
                    aFileUploads.push({ newFiles: [], existingFiles: listExpenses[i].expenseImages });
                }else{
                    aFileUploads.push({ newFiles: [], existingFiles: [] });
                }
            }
            setOriginalExpenseEntries(expenseEntries);
            const defaultValues = { fileUploads: aFileUploads, expenseEntries };
            reset(defaultValues);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const formatResponse = (res) => {
        return Object.fromEntries(
            Object.entries(res).map(([key, value]) => [key, value === null ? '' : value])
        );
    };

    const validateSubmit = async () => {
        handleSubmit(onSubmit)();
    };

    const validateSave = async () => {
        if (errors.balance) {
            setShowBalanceError(true);
            return;
        }
        clearErrors("balance");
        setShowBalanceError(false);
        handleSubmit(onSave)();
    };

    const getStatusKey = (value) => {
        return Object.keys(ExpenseStatusEnum).find((key) => ExpenseStatusEnum[key].value === value);
    };

    const prepareExpense = (origExpense) => {
        // Do not modify the existing expense object, clone it
        const expense = JSON.parse(JSON.stringify(origExpense));
        expense.bookingId = parseInt(expense.bookingId, 10);
        expense.clientId = expense.clientId !== '' ? expense.clientId : null;
        expense.mileage = expense.mileage !== '' ? expense.mileage : null;
        Object.keys(expense).forEach((i) => {
            if (_.isString(expense[i]) && expense[i].length === 0){
                expense[i] = null;
            }
         }   
        );
        return expense;
    };
    
    const transformToExpenseAPI = (expenses) => {
        let rtnExpense = expenses[0];
        rtnExpense.splitExpenses = [];
        rtnExpense.expenseImages = [];
        // Copy to splitExpenses
        for (let i = 1; i < expenses.length; i++) {
            expenses[i].expenseImages = [];
            rtnExpense.splitExpenses.push(expenses[i]);
        }

        return rtnExpense;
    };

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const expenses = data.expenseEntries.map(prepareExpense);
            let transformExpense = transformToExpenseAPI(expenses);
            const expenseResponse = await createExpense(transformExpense);
            const fileUploads = getValues(`fileUploads`);
            await uploadImages(fileUploads, expenseResponse, 'clinician-reimbursement');
        
            history.push({
                pathname: '/expenses/submit',
                state: { prevPage: '/expenses/clinician-reimbursement' }
            });
        } catch (error) {
            console.error('Error onSubmit Clinician Reimbursement Expense Entry');
            reset({ expenseEntries: data.expenseEntries, fileUploads: data.fileUploads });
            const message = formatErrorMessage(error);
            setAlert({
                message,
                type: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onSave = async (data) => {
        try {
            setIsLoading(true);
            const expenses = data.expenseEntries.map(prepareExpense);
            let transformExpense = transformToExpenseAPI(expenses);
            const expenseResponse = await updateExpense(transformExpense.id,transformExpense);
            const fileUploads = getValues(`fileUploads`);
            await uploadImages(fileUploads, expenseResponse, 'clinician-reimbursement');
           
            history.push({
                pathname: '/expenses/submit',
                state: { prevPage: '/expenses/review' }
            });
        } catch (error) {
            console.error('Error onSave Clinician Reimbursement Expense Entry');
            reset({ expenseEntries: data.expenseEntries, fileUploads: data.fileUploads });
            const message = formatErrorMessage(error);
            setAlert({
                message,
                type: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };


    const handleAddExpense = () => {
        const newExpense = {
            ...getValues('expenseEntries[0]'),
            label: 'New Expense',
            reimbursedAmount: '',
            billType: '',
            id: null,
            created: null,
            lastModified: null,
            status: 'New',
            source: 'LtAssociate'//This will Ensure any Split from a source: 'Clinician' will be change to 'LtAssociate'
        };

        const newFileUpload = {
            ...getValues('fileUploads[0]')
        };

        if (newFileUpload ){
            if (newFileUpload.existingFiles && newFileUpload.existingFiles.length >0){
                newFileUpload.existingFiles.forEach(file => {
                    // Clear out the Existing ExpenseId
                    file.expenseId=null;
                });
            }

            const cloneNewFiles = [];
            if (newFileUpload.newFiles && newFileUpload.newFiles.length >0){
                newFileUpload.newFiles.forEach(file => {
                    let cloneNewFile = new File([file], file.name, {type: file.type});
                    // Regenerate the preview to make unique for the tab                   
                    Object.assign(cloneNewFile, {
                        preview: URL.createObjectURL(file),
                    });
                    cloneNewFiles.push(cloneNewFile);
                });   
            }
            newFileUpload.newFiles = cloneNewFiles;

            const fileUploads = getValues(`fileUploads`);
            fileUploads.push(newFileUpload);
            setValue(`fileUploads`,fileUploads)
        }

        if (newExpense.hasOwnProperty('errors')) {
            delete newExpense.errors;
        }
        if (newExpense.hasOwnProperty('relatedErrors')) {
            delete newExpense.relatedErrors;
        }
        if (newExpense.hasOwnProperty('importedData')) {
            delete newExpense.importedData;
        }
        if (!newExpense.splitTransactionComment) {
            newExpense.splitTransactionComment = '';
        }
        append(newExpense);
        setActiveTabIndex(expenseEntries.length);
    };

    const resetImages = (index) => {
        //Resort/Reset Images
        const fileUploads = getValues(`fileUploads`);
        fileUploads.splice(index, 1);
        setValue(`fileUploads`,fileUploads)
    };

    const handleRemoveExpense = (index) => {
        //This was added to address a rendering issue
        //It turns off the TabPanel Display removes the item and then turns back on the display
        setActiveTabIndex(index);
        setRemoveTabs(true);
    };

    return (
        <Container maxWidth='xl' className={classes.ContainerRoot}>
            <FormProvider {...methods}>
                <Grid container direction='column' spacing={2}>
                    <ExpenseEntryHeader
                        title={title}
                        backLink={backLink}
                        expensesPath={expensesPath}
                    />
                    {isLoading ? (
                        <CircularProgress color='primary' />
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={4}>
                                        <Grid item sm={7} md={8} lg={9} xs={12}>
                                            <ExpenseTabs value={activeTabIndex}>
                                                {watchExpenseEntries.map((expense, index) => (
                                                    <ExpenseTab
                                                        key={expense.expenseEntryId}
                                                        id={`expense-tab-${index}`}
                                                        label={
                                                            <ExpenseTabLabel
                                                                isActive={activeTabIndex === index}
                                                                index={index}
                                                                handleRemoveTab={() =>
                                                                    handleRemoveExpense(index)
                                                                }
                                                                setValue={setActiveTabIndex}
                                                                tab={expense}
                                                                mode='clinician-reimbursement'
                                                            />
                                                        }
                                                    />
                                                ))}
                                            {(! (watchExpenseEntries.length === 1 && watchExpenseEntries[0].status === 'PendingSubmission')) && (
                                                <AddExpenseTab
                                                    label={<AddIcon color='primary' />}
                                                    disabled={watchExpenseEntries.length === 5}
                                                    onClick={handleAddExpense}
                                                    id='expense-tab-add'
                                                />
                                            )}
                                            </ExpenseTabs>
                                            {(!removeTabs) && (
                                                <div>
                                                    {watchExpenseEntries.map((expense, index) => (

                                                        <ExpenseTabPanel
                                                            className={classes.tabPanel}
                                                            key={`${expense.expenseEntryId}-panel`}
                                                            value={activeTabIndex}
                                                            index={index}
                                                            id={`expense-panel-${index}`}
                                                        >
                                                            {expense.errors && expense.errors.length > 0 && (
                                                                <ExpenseNeedsReviewAlert expense={expense} />
                                                            )}

                                                            <Grid
                                                                container
                                                                direction='column'
                                                                className={classes.formGridContainer}
                                                            >

                                                                <Grid item xs={12}>
                                                                    <BookingDetailsForm
                                                                        index={index}
                                                                        expense={expense}
                                                                        divisions={divisions}
                                                                        isSGA={[]}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <ClinicianExpenseDetailsForm
                                                                        index={index}
                                                                        expense={expense}
                                                                        originalExpenseEntries={originalExpenseEntries}
                                                                        expenseCategories={expenseCategories}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <ExpenseReceiptUploadForm
                                                                        index={index}
                                                                        expense={expense}
                                                                        originalExpenseEntries={originalExpenseEntries}
                                                                        mode='clinician-reimbursement'
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <InternalNotesForm
                                                                        index={index}
                                                                        expense={expense}
                                                                        originalExpenseEntries={originalExpenseEntries}
                                                                        statusesAllowed={statusesAllowed}
                                                                        isReimbursement={true}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <ReasonForSplittingForm
                                                                        index={index}
                                                                        expense={expense}
                                                                        originalExpenseEntries={originalExpenseEntries}
                                                                        isReimbursement={true}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </ExpenseTabPanel>
                                                    ))}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item md={4} sm={5} lg={3} xs={12}>
                                            <div className={classes.summary}>
                                                <ExpenseSummary setError={setError} clearErrors={clearErrors} setShowBalanceError={setShowBalanceError} showBalanceError={showBalanceError} setShowBalanceWarning={setShowBalanceWarning} isAnyApproved={isAnyApproved} isReimbursement={true}/>
                                                {showBalanceError && (
                                                    <Typography color="error">{errors.balance.message}</Typography>
                                                )}
                                                {showBalanceWarning && (
                                                    <Typography className={classes.warningMessage} color="inherit">Warning: The reimbursed amount exceeds the submitted amount. Please review the values before saving or submitting.</Typography>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            {(! (watchExpenseEntries.length === 1 && watchExpenseEntries[0].status === 'PendingSubmission')) && (
                            <Grid item xs={12}>
                                <ExpenseButtons
                                    validateSubmit={validateSubmit}
                                    validateSave={validateSave}
                                    defaultValues={defaultValues}
                                    setActiveTabIndex={setActiveTabIndex}
                                    expensesPath= {id ? '/expenses/review' : '/expenses'}
                                />
                            </Grid>
                            )}

                        </>
                    )}
                </Grid>
            </FormProvider>
            <Alert alert={alert} setAlert={setAlert} />
        </Container>
    );
};

export default withStyles(styles)(ClinicianReimbursement);

import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import AmountCurrencyInputAdornment from './AmountCurrencyInputAdornment';
import { valueAsExpense } from '../../Utils/ValueAsUtils';
import { ExpenseStatusValuesEnum } from './ExpenseStatus.enum';
import { AdminAuthContext } from '../../../Admin/Contexts/AdminAuthContext'

const ExpenseReimbursedAmount = (props) => {
	const { index, expense, mode, isPendingSubmission, isPosted } = props;
	const { control, errors } = useFormContext();
	const [required, setRequired] = useState({ required: false });
	const { isExpenseSystemAdministratorUser, isExpenseAdminUser } = useContext(AdminAuthContext);

	useEffect(() => {
		setRequired({ required: (expense?.status === ExpenseStatusValuesEnum.Approved) });
	}, [expense?.status]);

	return (
		<Grid item xs={12} md={6}>
			<Controller
				control={control}
				name={`expenseEntries[${index}].reimbursedAmount`}
				defaultValue={expense.reimbursedAmount !== null ? expense.reimbursedAmount : ''}
				rules={{
					required: required.required ? 'Reimbursed amount is required for approval' : false,
				}}
				render={({ onChange, value }) => (
					<ExpenseTextField
						{...required}
						placeholder=''
						id={`expense-reimbursed-amount`}
						onChange={(e) => onChange(valueAsExpense(e.target.value))}
						value={value}
						label='Reimbursed amount'
						endAdornment={
							<AmountCurrencyInputAdornment
								disabled={true}
								index={index}
								currencyType={'USD'}
							/>
						}
						error={Boolean(errors.expenseEntries?.[index]?.reimbursedAmount)}
						disabled={(!isExpenseAdminUser && !isExpenseSystemAdministratorUser) || isPendingSubmission || isPosted}
						helperText={errors.expenseEntries?.[index]?.reimbursedAmount?.message}
					/>
				)}
			/>
		</Grid>
	);
};

export default ExpenseReimbursedAmount;
